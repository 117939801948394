import _isNil from "lodash/isNil";
import _pick from "lodash/pick";
import _omitBy from "lodash/omitBy";
var initialState = { loading: true,
    login: false, currencyEditable: true, displayCurrency: "US$", dialogOpen: false, currentDialog: "login", onDialogClose: null, dialogLastPage: "", errorCode: "", errorData: null, pdfRichtext: null, notificationShow: true, notificationHeight: 0, isWebView: false, isAppleATT: false, footerHeight: 0 //...
};
export default function todosReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    switch (action.type) { // case "ui/setLang": {
        //   return { ...state, uiLang: action.payload };
        // }
        case "ui/login": {
            return Object.assign({}, state, { login: true });
        }
        case "ui/logout": {
            return Object.assign({}, state, { login: false });
        }
        case "ui/setWebView": {
            return Object.assign({}, state, { isWebView: true });
        }
        case "ui/setAppleATT": {
            return Object.assign({}, state, { isAppleATT: true });
        }
        case "ui/toggleDisplayCurrency": {
            return Object.assign({}, state, { displayCurrency: state.displayCurrency === "US$" ? "HK$" : "US$", currencyEditable: true });
        }
        case "ui/setDisplayCurrency": {
            return Object.assign({}, state, { displayCurrency: action.payload.displayCurrency, currencyEditable: action.payload.currencyEditable });
        }
        case "ui/setDialogOpen": {
            if (state.onDialogClose && action.payload.status === false) {
                var _state, _state$onDialogClose;
                (_state = state) === null || _state === void 0 ? void 0 : (_state$onDialogClose = _state.onDialogClose) === null || _state$onDialogClose === void 0 ? void 0 : _state$onDialogClose.call(_state);
            }
            return Object.assign({}, state, { dialogOpen: action.payload.status, currentDialog: action.payload.page ? action.payload.page : state.currentDialog, dialogLastPage: "", alert: "", onDialogClose: action.payload.onDialogClose }, _omitBy(_pick(action.payload, ["errorCode"]), _isNil));
        }
        case "ui/setCurrentDialog": {
            return Object.assign({}, state, { dialogLastPage: state.currentDialog, currentDialog: action.payload });
        }
        case "ui/setDialogLastPage": {
            return Object.assign({}, state, { dialogLastPage: action.payload });
        }
        case "ui/setAlertDialog": {
            var _action$payload$error;
            return Object.assign({}, state, { dialogOpen: true, currentDialog: "alert", errorCode: (_action$payload$error = action.payload.errorId) !== null && _action$payload$error !== void 0 ? _action$payload$error : action.payload, onDialogClose: action.payload.onDialogClose, dialogLastPage: "" });
        }
        case "ui/setErrorData": {
            return Object.assign({}, state, { errorData: action.payload });
        }
        case "ui/previousDialog": {
            return Object.assign({}, state, { currentDialog: state.dialogLastPage });
        }
        case "ui/setPdfRichtext": {
            return Object.assign({}, state, { pdfRichtext: action.payload });
        }
        case "ui/showNotification": {
            return Object.assign({}, state, { notificationShow: action.payload });
        }
        case "ui/setNotificationHeight": {
            return Object.assign({}, state, { notificationHeight: action.payload });
        }
        case "ui/setPlanInfo": {
            return Object.assign({}, state, { planInfo: action.payload });
        }
        case "ui/setCmsComponentId": {
            return Object.assign({}, state, { cmsComponentId: action.payload });
        }
        case "ui/setFooterHeight": {
            return Object.assign({}, state, { footerHeight: action.payload });
        }
        case "ui/setErrorCode": {
            return Object.assign({}, state, { errorCode: action.payload });
        }
        default: return state;
    }
}
