import { combineReducers } from "redux";
import { qnbProductSlice } from "./qnbProductSlice";
import { qnbSlice } from "./qnbSlice";
import { siteSlice } from "./siteSlice";
import { affinitySlice } from "./affinitySlice";

import uiReducer from "./uiSlice";
import commonReducer from "./commonSlice";
import {weChatSlice} from "./weChatSlice";
import { policyFinderSlice } from "./policyFinderSlice";

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  ui: uiReducer,
  qnbProduct: qnbProductSlice.reducer,
  qnb: qnbSlice.reducer,
  site: siteSlice.reducer,
  affinity: affinitySlice.reducer,
  commonStates: commonReducer,
  weChat: weChatSlice.reducer,
  policyFinder: policyFinderSlice.reducer,
});

export default rootReducer;
