export function scrollToOffset(toElement, offset) { try {
    var _element$getBoundingC;
    var element = document.querySelector(toElement);
    var elementPosition = (_element$getBoundingC = element === null || element === void 0 ? void 0 : element.getBoundingClientRect().top) !== null && _element$getBoundingC !== void 0 ? _element$getBoundingC : 0;
    var offsetPosition = elementPosition + window.pageYOffset - offset;
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
}
catch (error) { } }
export function scrollToWithBarOffset(toElement) { try {
    var _element$getBoundingC2, _document$querySelect, _document$querySelect2, _document$querySelect3, _document$querySelect4;
    var element = document.querySelector(toElement !== null && toElement !== void 0 ? toElement : "");
    var elementPosition = (_element$getBoundingC2 = element === null || element === void 0 ? void 0 : element.getBoundingClientRect().top) !== null && _element$getBoundingC2 !== void 0 ? _element$getBoundingC2 : 0;
    var scrollUp = elementPosition < 0;
    var navBarHeight = (_document$querySelect = (_document$querySelect2 = document.querySelector("#nav-bar-container")) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.clientHeight) !== null && _document$querySelect !== void 0 ? _document$querySelect : 250;
    var stickyBarHeight = (_document$querySelect3 = (_document$querySelect4 = document.querySelector("#sticky-bar")) === null || _document$querySelect4 === void 0 ? void 0 : _document$querySelect4.clientHeight) !== null && _document$querySelect3 !== void 0 ? _document$querySelect3 : 80;
    var headerOffset = stickyBarHeight + (scrollUp ? navBarHeight : 0);
    scrollToOffset(toElement, headerOffset);
}
catch (error) { } }
