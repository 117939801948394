import { useStaticQuery } from "gatsby";
import { getMapping } from "../../blue-library/utils/mapping";
var defaultProps = { type: "Asset", lang: "hk" };
var useCmsData = function useCmsData(props) {
    props = Object.assign({}, defaultProps, props);
    var _ref = useStaticQuery("2771066325"), allContentfulAlertMsg = _ref.allContentfulAlertMsg, allContentfulComponents = _ref.allContentfulComponents, allContentfulInputId = _ref.allContentfulInputId, allContentfulJourney = _ref.allContentfulJourney, allContentfulPageId = _ref.allContentfulPageId, allContentfulPromotion = _ref.allContentfulPromotion, allContentfulAsset = _ref.allContentfulAsset, allContentfulVideoComponent = _ref.allContentfulVideoComponent, allContentfulPopupStyle = _ref.allContentfulPopupStyle, allContentfulStickyMenuComponent = _ref.allContentfulStickyMenuComponent, allContentfulAnnouncement = _ref.allContentfulAnnouncement; // graphqlData.filter()
    var filter = function filter(data) { var _ref2; var cmsLang = getMapping("lang")[(_ref2 = props.lang) !== null && _ref2 !== void 0 ? _ref2 : "hk"]; var result = data.edges.filter(function (item) { return item.node.node_locale == cmsLang; }); return result; };
    var getDefaultOgImage = function getDefaultOgImage(data) { var _ref3; var cmsLang = getMapping("lang")[(_ref3 = props.lang) !== null && _ref3 !== void 0 ? _ref3 : "hk"]; var result = data.edges.filter(function (item) { return item.node.node_locale == cmsLang && item.node.title == "Default OG Image"; }); if (result && result.length > 0) {
        try {
            return result[0].node.file.url;
        }
        catch (e) {
            return null;
        }
    } return null; };
    switch (props.type) {
        case "AlertMsg": return filter(allContentfulAlertMsg);
        case "Components": return filter(allContentfulComponents);
        case "InputId": return filter(allContentfulInputId);
        case "Journey": return filter(allContentfulJourney);
        case "PageId": return filter(allContentfulPageId);
        case "Promotion": return filter(allContentfulPromotion);
        case "Asset": return filter(allContentfulAsset);
        case "VideoComponent": return filter(allContentfulVideoComponent);
        case "PopupStyle": return filter(allContentfulPopupStyle);
        case "StickyMenu": return filter(allContentfulStickyMenuComponent);
        case "Announcement": return filter(allContentfulAnnouncement);
        case "DefaultOGImage": return getDefaultOgImage(allContentfulAsset);
    }
};
export { useCmsData };
var getCmsData = function getCmsData(allData, id, contentType) { var _apiDataResult$11; var apiDataResult; switch (contentType) {
    case "AlertMsg":
        apiDataResult = allData.filter(function (item) { return item.node.alertId == id; });
        if (apiDataResult[0]) {
            var _apiDataResult$, _apiDataResult$2, _apiDataResult$3, _apiDataResult$4, _apiDataResult$5, _apiDataResult$6;
            return { id: id, displayCode: (_apiDataResult$ = apiDataResult[0]) === null || _apiDataResult$ === void 0 ? void 0 : _apiDataResult$.node.displayCode, alertStyle: (_apiDataResult$2 = apiDataResult[0]) === null || _apiDataResult$2 === void 0 ? void 0 : _apiDataResult$2.node.alertStyle, title: (_apiDataResult$3 = apiDataResult[0]) === null || _apiDataResult$3 === void 0 ? void 0 : _apiDataResult$3.node.title, body: (_apiDataResult$4 = apiDataResult[0]) === null || _apiDataResult$4 === void 0 ? void 0 : _apiDataResult$4.node.body, tipsStyle: (_apiDataResult$5 = apiDataResult[0]) === null || _apiDataResult$5 === void 0 ? void 0 : _apiDataResult$5.node.tipsStyle, tips: (_apiDataResult$6 = apiDataResult[0]) === null || _apiDataResult$6 === void 0 ? void 0 : _apiDataResult$6.node.tips };
        }
        else {
            return undefined;
        }
    case "PopupStyle":
        apiDataResult = allData.filter(function (item) { return item.node.popupStyle == id; });
        if (apiDataResult[0]) {
            var _apiDataResult$7, _apiDataResult$8, _apiDataResult$9, _apiDataResult$10, _apiDataResult$10$nod, _apiDataResult$10$nod2, _apiDataResult$10$nod3;
            return { id: id, popupStyle: (_apiDataResult$7 = apiDataResult[0]) === null || _apiDataResult$7 === void 0 ? void 0 : _apiDataResult$7.node.popupStyle, bgColorEnd: (_apiDataResult$8 = apiDataResult[0]) === null || _apiDataResult$8 === void 0 ? void 0 : _apiDataResult$8.node.bgColorEnd, bgColorStart: (_apiDataResult$9 = apiDataResult[0]) === null || _apiDataResult$9 === void 0 ? void 0 : _apiDataResult$9.node.bgColorStart, iconPublicURL: (_apiDataResult$10 = apiDataResult[0]) === null || _apiDataResult$10 === void 0 ? void 0 : (_apiDataResult$10$nod = _apiDataResult$10.node) === null || _apiDataResult$10$nod === void 0 ? void 0 : (_apiDataResult$10$nod2 = _apiDataResult$10$nod.icon) === null || _apiDataResult$10$nod2 === void 0 ? void 0 : (_apiDataResult$10$nod3 = _apiDataResult$10$nod2.localFile) === null || _apiDataResult$10$nod3 === void 0 ? void 0 : _apiDataResult$10$nod3.publicURL };
        }
        else {
            return {};
        }
    case "Components":
        apiDataResult = allData.filter(function (item) { return item.node.componentId == id; });
        return { id: id, body: (_apiDataResult$11 = apiDataResult[0]) === null || _apiDataResult$11 === void 0 ? void 0 : _apiDataResult$11.node.body };
} };
export { getCmsData };
