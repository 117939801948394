var initialState = { editEnable: false, unableToPurchase: false, loading: 0, isCompleted: false, onChange: false, prevPremium: 0, basicPremium: 0, toggleEnable: false, slideBar: false, enableDefaultSlideBar: false, changeNextVal: false, additionalPremium: 0, coverageValue: 0, declinedToast: false, bmiAgeError: "", switchLanguage: false, quotaFull: false, invalidPromoCode: false, success: false, invitationCode: "", weChatInvalidOtp: "", isTilSixtyFive: false, maintenancePage: false, isPolicyFinderSubmitted: false, otherClaimDropDown1: null, otherClaimDropDown2: [], selectedClaim: "", currentClaimPage: "eclaim", makeInputValueEmpty: false, planDetailsPage: false, tnc: false, viewDeclaration: false };
export default function commonStateReducer(state, action) { if (state === void 0) {
    state = initialState;
} switch (action.type) {
    case "BMI_AGE_ERROR": {
        return Object.assign({}, state, { bmiAgeError: action.payload });
    }
    case "EDIT_TRUE": {
        return Object.assign({}, state, { editEnable: true });
    }
    case "EDIT_FALSE": {
        return Object.assign({}, state, { editEnable: false });
    }
    case "UNABLE_TO_PURCHASE_TRUE": {
        return Object.assign({}, state, { unableToPurchase: true });
    }
    case "UNABLE_TO_PURCHASE_FALSE": {
        return Object.assign({}, state, { unableToPurchase: false });
    }
    case "UPDATE_LOADING": {
        return Object.assign({}, state, { loading: action.payload });
    }
    case "IS_COMPLETED_TRUE": {
        return Object.assign({}, state, { isCompleted: true });
    }
    case "IS_COMPLETED_FALSE": {
        return Object.assign({}, state, { isCompleted: false });
    }
    case "ON_CHANGE_TRUE": {
        return Object.assign({}, state, { onchange: true });
    }
    case "ON_CHANGE_FALSE": {
        return Object.assign({}, state, { onchange: false });
    }
    case "UPDATE_PREV_PREMIUM": {
        return Object.assign({}, state, { prevPremium: action.payload });
    }
    case "TOGGLE_TRUE": {
        return Object.assign({}, state, { toggleEnable: true });
    }
    case "TOGGLE_FALSE": {
        return Object.assign({}, state, { toggleEnable: false });
    }
    case "SLIDE_BAR_TRUE": {
        return Object.assign({}, state, { slideBar: true });
    }
    case "SLIDE_BAR_FALSE": {
        return Object.assign({}, state, { slideBar: false });
    }
    case "DEFAULT_SLIDE_BAR_TRUE": {
        return Object.assign({}, state, { enableDefaultSlideBar: true });
    }
    case "DEFAULT_SLIDE_BAR_FALSE": {
        return Object.assign({}, state, { enableDefaultSlideBar: false });
    }
    case "NEXT_TRUE": {
        return Object.assign({}, state, { changeNextVal: true });
    }
    case "NEXT_FALSE": {
        return Object.assign({}, state, { changeNextVal: false });
    }
    case "UPDATE_ADDITIONAL_PREMIUM": {
        return Object.assign({}, state, { additionalPremium: action.payload });
    }
    case "UPDATE_COVERAGE_VALUE": {
        return Object.assign({}, state, { coverageValue: action.payload });
    }
    case "DEFAULT_TOAST_TRUE": {
        return Object.assign({}, state, { declinedToast: true });
    }
    case "DEFAULT_TOAST_FALSE": {
        return Object.assign({}, state, { declinedToast: false });
    }
    case "SWITCH_LANGUAGE_TRUE": {
        return Object.assign({}, state, { switchLanguage: true });
    }
    case "SWITCH_LANGUAGE_FALSE": {
        return Object.assign({}, state, { switchLanguage: false });
    }
    case "IS65_TRUE": {
        return Object.assign({}, state, { isTilSixtyFive: true });
    }
    case "IS65_FALSE": {
        return Object.assign({}, state, { isTilSixtyFive: false });
    }
    case "QUOTA_FULLl_TRUE": {
        return Object.assign({}, state, { quotaFull: true });
    }
    case "QUOTA_FULLl_FALSE": {
        return Object.assign({}, state, { quotaFull: false });
    }
    case "INVALID_PROMO_CODE_TRUE": {
        return Object.assign({}, state, { invalidPromoCode: true });
    }
    case "INVALID_PROMO_CODE_FALSE": {
        return Object.assign({}, state, { invalidPromoCode: false });
    }
    case "WECHAT_SUCCESS_TRUE": {
        return Object.assign({}, state, { success: true });
    }
    case "WECHAT_SUCCESS_FALSE": {
        return Object.assign({}, state, { success: false });
    }
    case "UPDATE_INVITATION_CODE": {
        return Object.assign({}, state, { invitationCode: action.payload });
    }
    case "WECHAT_INVALID_OTP_TRUE": {
        return Object.assign({}, state, { weChatInvalidOtp: true });
    }
    case "WECHAT_INVALID_OTP_FALSE": {
        return Object.assign({}, state, { weChatInvalidOtp: false });
    }
    case "MAINTENANCE_PAGE_TRUE": {
        return Object.assign({}, state, { maintenancePage: true });
    }
    case "MAINTENANCE_PAGE_FALSE": {
        return Object.assign({}, state, { maintenancePage: false });
    }
    case "POLICYFINDER_SUBMITTED": {
        return Object.assign({}, state, { isPolicyFinderSubmitted: true });
    }
    case "POLICYFINDER_NOT_SUBMITTED": {
        return Object.assign({}, state, { isPolicyFinderSubmitted: false });
    }
    case "UPDATE_OTHER_CLAIM_DROPDOWN_1": {
        return Object.assign({}, state, { otherClaimDropDown1: action.payload });
    }
    case "UPDATE_OTHER_CLAIM_DROPDOWN_2": {
        return Object.assign({}, state, { otherClaimDropDown2: action.payload });
    }
    case "UPDATE_SELECTED_CLAIM": {
        return Object.assign({}, state, { selectedClaim: action.payload });
    }
    case "UPDATE_CURRENT_PAGE": {
        return Object.assign({}, state, { currentClaimPage: action.payload });
    }
    case "INPUT_VALUE_EMPTY_TRUE": {
        return Object.assign({}, state, { makeInputValueEmpty: true });
    }
    case "INPUT_VALUE_EMPTY_FALSE": {
        return Object.assign({}, state, { makeInputValueEmpty: false });
    }
    case "PLAN_DETAILS_PAGE_TRUE": {
        return Object.assign({}, state, { planDetailsPage: true });
    }
    case "PLAN_DETAILS_PAGE_FALSE": {
        return Object.assign({}, state, { planDetailsPage: false });
    }
    case "TNC_PAGE_TRUE": {
        return Object.assign({}, state, { tnc: true });
    }
    case "TNC_PAGE_FALSE": {
        return Object.assign({}, state, { tnc: false });
    }
    case "SUMMARY_PAGE_TRUE": {
        return Object.assign({}, state, { viewDeclaration: true });
    }
    case "SUMMARY_PAGE_FALSE": {
        return Object.assign({}, state, { viewDeclaration: false });
    }
    default: return state;
} }
