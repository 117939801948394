import moment from "moment";
var getValidationResult = function getValidationResult(fieldName, errorCode, validatorValue) { var result = { fieldName: fieldName, errorCode: errorCode !== null && errorCode !== void 0 ? errorCode : "" }; if (validatorValue) {
    result.validatorValue = validatorValue;
} return result; };
var isBlank = function isBlank(input) { if (typeof input === "string") {
    return input === null || input === "" || input === undefined || input.trim() === "";
}
else if (typeof input === "number") {
    return input === null || input === undefined;
}
else if (typeof input === "boolean" && input) {
    return input === null || input === undefined;
}
else {
    if (Array.isArray(input) && input[0]) {
        return input === null || input === undefined;
    }
    return true;
} };
export function required(fieldName, values) { var value = values[fieldName]; if (isBlank(value)) {
    return getValidationResult(fieldName, "REQUIRED");
} }
export function email(fieldName, values) {
    var value = values[fieldName];
    if (isBlank(value)) {
        return null;
    } // tslint:disable-next-line:max-line-length
    var pattern = /(^$|^[\w-\\+]+(\.[\w]+)*@[\w-]+(\.[\w]+)*(\.[a-z]{2,})$)/g;
    return !pattern.test(value) ? getValidationResult(fieldName, "INVALID") : null;
}
export function minValue(min) {
    return function (fieldName, values) {
        var value = values[fieldName]; // console.log("minValue " + typeof value, value);
        if (typeof value === "number") {
            if (value === undefined || isNaN(value)) {
                return null;
            }
            if (value < min) {
                return getValidationResult(fieldName, "MINVALUE", String(min));
            }
        }
        else if (typeof value === "string") {
            if (value === undefined || value.trim() === "") {
                return null;
            }
            if (parseInt(value, 10) < min) {
                return getValidationResult(fieldName, "MINVALUE", String(min));
            }
        }
    };
}
export function maxValue(max) {
    return function (fieldName, values) {
        var value = values[fieldName];
        if (typeof value === "number") {
            if (value === undefined || isNaN(value)) {
                return null;
            }
            if (value > max) {
                return getValidationResult(fieldName, "MAXVALUE", String(max));
            }
        }
        else if (typeof value === "string") {
            if (value === undefined || value.trim() === "") {
                return null;
            }
            if (parseInt(value, 10) > max) {
                return getValidationResult(fieldName, "MAXVALUE", String(max));
            }
        }
    };
}
export function required18YearsOld(fieldName, values) {
    var value = values[fieldName];
    if (!value) {
        return null;
    }
    if (moment(value).isValid()) {
        var age = moment().diff(value, "years"); // console.log("age", age);
        if (age < 18)
            return getValidationResult(fieldName, "REQUIRED_18_YEARS_OLD");
    }
    return null;
}
export function valueShouldMatch(targetField) { return function (fieldName, values) { var value = values[fieldName]; var targetValue = values[targetField]; return value !== targetValue ? getValidationResult(fieldName, "VALUE_SHOULD_MATCH", targetField.toUpperCase() + ".LABEL") : null; }; }
export function english(fieldName, values) { var value = values[fieldName]; if (isBlank(value)) {
    return null;
} var pattern = /^[a-zA-Z -]*$/; return pattern.test(value) ? null : getValidationResult(fieldName, "ENGLISH"); }
export function chinese(fieldName, values) {
    var value = values[fieldName];
    if (isBlank(value)) {
        return null;
    }
    value = value.replace(/(^\s*)|(\s*$)/g, "");
    if (value === "") {
        return null;
    }
    var re1 = new RegExp("^[\u4E00-\uFA29]*$"); // chinese character range
    var re2 = new RegExp("^[\uE7C7-\uE7F3]*$"); // Chinese character range
    return !re1.test(value) && !re2.test(value) ? getValidationResult(fieldName, "CHINESE") : null;
}
export function number(fieldName, values) { var value = values[fieldName]; if (isBlank(value)) {
    return null;
} var pattern = /^[0-9]/; return pattern.test(value) ? null : getValidationResult(fieldName, "NUMBER"); }
export function englishOnly(s) { return /^[a-zA-Z.,?<>。，'\-—=;@！!+\s$]+$/.test(s); }
export function chineseOnly(s) { return /^[\u4E00-\uFA29\uE7C7-\uE7F3]+$/.test(s); }
export function chineseOnlyOrEmpty(s) { if (s == null || s.length === 0) {
    return true;
}
else {
    return chineseOnly(s);
} }
export function digitOnly(s) { return (s !== null && s !== void 0 ? s : "").length === 0 || /^\d+$/.test(s); }
export function hkidCheckDigit(str) {
    if (!str)
        return false;
    var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; // basic check length
    if (str.length < 8)
        return false; // handling bracket
    if (str.charAt(str.length - 3) == "(" && str.charAt(str.length - 1) == ")")
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2); // convert to upper case
    str = str.toUpperCase(); // regular expression to check pattern and split
    var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    var matchArray = str.match(hkidPat); // not match, return false
    if (matchArray == null)
        return false; // the character part, numeric part and check digit part
    var charPart = matchArray[1];
    var numPart = matchArray[2];
    var checkDigit = matchArray[3]; // calculate the checksum for character part
    var checkSum = 0;
    if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    }
    else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    } // calculate the checksum for numeric part
    for (var i = 0, j = 7; i < numPart.length; i++, j--) {
        checkSum += j * numPart.charAt(i);
    } // verify the check digit
    var remaining = checkSum % 11;
    var verify = remaining == 0 ? 0 : 11 - remaining;
    return verify == checkDigit || verify == 10 && checkDigit == "A";
}
export function checkPasswordRule(rule, password) { if (rule.every(function (e) { return e.regex.test(password); })) {
    return true;
}
else {
    return false;
} }
export function andrexPolicyCheck(s) { return (s !== null && s !== void 0 ? s : "").length === 0 || /^X7\d{6}$/.test(s); }
