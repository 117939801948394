function currencyDisplay(value) { var formatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }); return formatter.format(value); }
export { currencyDisplay };
export function calculatePercentage(value) {
    //     maximumFractionDigits: 2,
    //   });
    //   return formatter.format(value);
    var multiplier = Math.pow(10, 2), adjustedNum = value * multiplier, truncatedNum = Math[adjustedNum < 0 ? "ceil" : "floor"](adjustedNum);
    return (truncatedNum / multiplier).toFixed(1);
}
function calculateDisplay(value, currency, exchangeRate) { if (currency === "HK$")
    return value * exchangeRate; return value; }
export { calculateDisplay };
function bytesToSize(bytes) { var sizes = ["B", "KB", "MB", "GB", "TB", "PB"]; for (var i = 0; i < sizes.length; i++) {
    if (bytes <= 1024) {
        return bytes + " " + sizes[i];
    }
    else {
        bytes = Number(parseFloat(bytes / 1024 + "").toFixed(2));
    }
} return bytes + " P"; }
export { bytesToSize };
export var intToRoman = function intToRoman(num) { var map = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }; var result = ""; for (var key in map) {
    var repeatCounter = Math.floor(num / map[key]);
    if (repeatCounter !== 0) {
        result += key.repeat(repeatCounter);
    }
    num %= map[key];
    if (num === 0)
        return result;
} return result; };
export var parseNumber = function parseNumber(n) { return Number(n).toFixed(2); };
export var parseCurrency = function parseCurrency(n, fixed) { var formatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: fixed !== null && fixed !== void 0 ? fixed : 0, minimumFractionDigits: fixed !== null && fixed !== void 0 ? fixed : 0 }); return formatter.format(n); };
